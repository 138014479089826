body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

td {
  border: 1px solid black;
}

[data-loader="obj"] {
  width: initial !important;
  height: initial !important;
}

@media (max-width: 1380px) {
  canvas,
  .model-viewer-placeholder {
    width: 300px !important;
    height: 300px !important;
  }
}

@media (max-width: 990px) {
  canvas,
  .model-viewer-placeholder {
    width: 200px !important;
    height: 200px !important;
  }
}

@media (max-width: 790px) {
  table {
    display: flex;
    flex-direction: column;
  }

  td {
    border: none;
  }

  tr {
    display: flex;
    flex-direction: column;
    margin: 1em 0;
    border: 1px solid black;
  }

  th,
  td {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

button {
  background-color: #01a7ff;
  color: white;
  border: 1px solid #005d8f;
  font-size: inherit;
  font-family: inherit;
  text-align: inherit;
  border-radius: 4px;
}
